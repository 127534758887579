//fonts
@import url('https://fonts.googleapis.com/css?family=Staatliches:regular,400&display=swap');
@import url('https://fonts.googleapis.com/css?family=Montserrat:100,100italic,200,200italic,300,300italic,regular,italic,500,500italic,600,600italic,700,700italic,800,800italic,900,900italic&display=swap');

//style
@import './index';
@import './_toastify';
@import './_tippy';

// CUSTOM CLASS

.USDC {
  color: $violet !important;
}

.NTT {
  color: $turquoise !important;
}

.violet {
  color: $violet !important;
}

.violetLink {
  color: $violet !important;
  cursor: pointer;

  &:hover {
    color: #dd95ff !important;
  }

  &:active {
    color: #9748bc !important;
  }
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* Указываем box sizing */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Убираем внутренние отступы */
ul[class],
ol[class] {
  padding: 0;
}

/* Убираем внешние отступы */
body,
h1,
h2,
h3,
h4,
p,
ul[class],
ol[class],
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
}

/* Выставляем основные настройки по-умолчанию для body */
body {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 100%;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  background-color: #111212;
  color: $white;
}

/* Удаляем стандартную стилизацию для всех ul и il, у которых есть атрибут class*/
ul[class],
ol[class] {
  list-style: none;
}

/* Элементы a, у которых нет класса, сбрасываем до дефолтных стилей */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Упрощаем работу с изображениями */
img {
  max-width: 100%;
  display: block;
}

/* Указываем понятную периодичность в потоке данных у article*/
article > * + * {
  margin-top: 1em;
}

/* Наследуем шрифты для инпутов и кнопок */
input,
button,
textarea,
select {
  font: inherit;
}

h1 {
  font-family: 'Staatliches';
  font-style: normal;
  font-weight: normal;
  font-size: 36px;
  line-height: 120%;
  letter-spacing: 0.15em;
  text-transform: uppercase;
}

h2 {
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 120%;
}

/* Удаляем все анимации и переходы для людей, которые предпочитай их не использовать */
@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

.customWrapper .react-datepicker__input-container input {
  display: flex;
  width: 100%;
  height: 36px;
  background-color: rgba(255, 255, 255, 0.05);
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  padding: 10px;
  letter-spacing: 0.1em;
  color: #ffffff;
  box-shadow: none;
  outline: none;
  border: none;
  border-bottom: 1px solid rgba(#ffffff, 0.2);
  box-sizing: border-box;
  font-weight: 600;
}

.filterWrapper .react-datepicker__input-container input {
  display: flex;
  width: 230px;
  height: 36px;
  background-color: rgba(255, 255, 255, 0.05);
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  padding: 10px;
  letter-spacing: 0.1em;
  color: #ffffff;
  box-shadow: none;
  outline: none;
  border: none;
  border-bottom: 1px solid rgba(#ffffff, 0.2);
  box-sizing: border-box;
  font-weight: 600;
}

.react-datepicker__tab-loop {
  position: relative;
}

.customPopper {
  .react-datepicker {
    background: rgba(255, 255, 255, 0.1);
    -webkit-backdrop-filter: blur(7px);
    backdrop-filter: blur(7px);
    border: none;
    border-radius: 0;
    display: flex;

    .react-datepicker__triangle::after,
    .react-datepicker__triangle::before {
      border: none;
    }

    &__triangle {
      display: none;
    }

    &__year {
      &-text {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 70%;
        color: #ffffff;
        text-align: center;
        padding-top: 15px;
        width: 50px;
        height: 40px;

        &:hover {
          color: #000;
        }

        &.react-datepicker__day--disabled {
          opacity: 0.5;

          &:hover {
            color: #ffffff;
          }
        }
      }

      .react-datepicker__day--selected {
        background: $violet;
      }

      &-wrapper {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        max-width: none;

      }
    }

    .react-datepicker__month-container {
      border-radius: 0;

      .react-datepicker__month {
        background: rgba(255, 255, 255, 0.001);
        -webkit-backdrop-filter: blur(7px);
        backdrop-filter: blur(7px);
        border: none;
        margin: 0;
        width: 398px;

        .react-datepicker__week {
          .react-datepicker__day {
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 70%;
            color: #ffffff;
            text-align: center;
            padding-top: 15px;
            width: 50px;
            height: 40px;

            &:hover {
              color: #000;
            }

            &.react-datepicker__day--disabled {
              opacity: 0.5;

              &:hover {
                color: #ffffff;
              }
            }
          }

          .react-datepicker__day--selected {
            background: $violet;
          }
        }
      }

      .react-datepicker__header {
        padding-top: 15px;
        font-family: 'Staatliches';
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 110%;
        width: 398px;
        background: rgba(255, 255, 255, 0.01);
        -webkit-backdrop-filter: blur(7px);
        backdrop-filter: blur(7px);
        border: none;
        border-radius: 0;

        .react-datepicker__current-month {
          color: #ffffff;
        }

        .react-datepicker__day-names {
          font-family: 'Staatliches';
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 70%;

          .react-datepicker__day-name {
            color: #ffffff;
            opacity: 0.3;
            width: 50px;
            margin-bottom: 29px;
            margin-top: 29px;
          }
        }
      }
    }
  }
}

.blockedUserPopper,
.filterPopper {
  margin-top: -10px;

  .react-datepicker {
    background: rgb(26, 26, 26);
    -webkit-backdrop-filter: blur(7px);
    backdrop-filter: blur(7px);
    border: none;
    border-radius: 0;

    .react-datepicker__triangle::after,
    .react-datepicker__triangle::before {
      border: none;
    }

    .react-datepicker__month-container {
      border-radius: 0;

      .react-datepicker__month {
        background: rgba(182, 127, 127, 0.001);
        // -webkit-backdrop-filter: blur(7px);
        // backdrop-filter: blur(7px);
        border: none;
        margin: 0;
        width: 480px;
        padding-bottom: 15px;

        .react-datepicker__week {
          .react-datepicker__day {
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 70%;
            color: #ffffff;
            text-align: center;
            padding-top: 5px;
            width: 25px;
            height: 25px;
            text-align: center;

            &:hover {
              color: #000;
            }

            &.react-datepicker__day--disabled {
              opacity: 0.5;

              &:hover {
                color: #ffffff;
              }
            }
          }

          .react-datepicker__day--selected {
            background: $violet;
          }
        }
      }

      .react-datepicker__header {
        // padding-top: 15px;
        font-family: 'Staatliches';
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 110%;
        width: 480px;
        // background: rgba(255, 255, 255, 0.01);
        -webkit-backdrop-filter: blur(7px);
        backdrop-filter: blur(7px);
        border: none;
        border-radius: 0;
        padding-top: 30px;

        .react-datepicker__current-month {
          color: #ffffff;
        }

        .react-datepicker__day-names {
          font-family: 'Staatliches';
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 70%;

          .react-datepicker__day-name {
            color: #ffffff;
            opacity: 0.3;
            width: 25px;
            margin-bottom: 15px;
            margin-top: 15px;
          }
        }
      }
    }
  }
}

.filterPopper {
  .react-datepicker {
    .react-datepicker__month-container {
      .react-datepicker__month {
        width: 230px;
      }

      .react-datepicker__header {
        width: 230px;
      }
    }
  }
}

.customStyle {
  .react-datepicker__time-container {
    border: none;

    .react-datepicker__time {
      background: transparent;
    }
  }

  .react-datepicker__header {
    background: transparent;
  }

  .react-datepicker-time__header {
    color: #fff;
  }

  .react-datepicker__header--time {
    font-family: 'Staatliches';
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 110%;
    color: #fff;
    padding: 15px 10px;
    border: none;
  }

  .react-datepicker__time-container {
    .react-datepicker__time {
      .react-datepicker__time-box {
        ul.react-datepicker__time-list {
          font-family: 'Montserrat';
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 70%;
          color: #ffffff;
          text-align: center;
        }

        li {
          &.react-datepicker__time-list-item {
            display: flex;
            align-items: center;
            justify-content: center;

            &:hover {
              color: #000;
            }

            &.react-datepicker__time-list-item--selected {
              background-color: $violet;
            }
          }
        }
      }
    }
  }
}

.ui.card {
  margin: 0;
}

.ui.card > :first-child,
.ui.card > :last-child {
  border-radius: 0px !important;
}

.ui.card > .content:after {
  content: none !important;
}
