@import '../../../../assets/scss/index.scss';

.menuWrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 35px 20px 30px 30px;

  .wrapList {
    width: 100%;
    display: flex;
    margin-top: 58px;
    flex-direction: column;
    height: 100%;
  }

  .list {
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    margin-top: -20px;
    position: relative;
    height: calc(100vh - 65px - 35px - 58px - 36px - 35px - 40px - 30px);
    overflow: auto;
  }

  .indicator {
    position: absolute;
    width: 4px;
    height: 30px;
    left: 20px;
    top: 267px;
    background: $violet;
    left: 0;
    top: -5px;
    transition: $transitionEase;
  }

  .link {
    padding-left: 24px;
    font-family: 'Staatliches';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 100%;
    letter-spacing: 0.15em;
    text-transform: uppercase;
    text-decoration: none;
    color: rgba($white, 0.5);
    transition: $transitionEase;

    &.isActive {
      color: $white;
    }
  }

  .linkWrap {
    &:not(:last-of-type) {
      margin-bottom: 30px;
    }
  }

  .subMenuItemWrap {
    display: flex;
    flex-direction: column;

    .subLing {
      font-family: 'Staatliches';
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 100%;
      letter-spacing: 0.15em;
      text-transform: uppercase;
      text-transform: uppercase;
      text-decoration: none;
      color: rgba($white, 0.5);
      transition: $transitionEase;
      margin-left: 44px;

      &.isActive {
        color: $white;
      }

      &:first-of-type {
        margin-top: 30px;
      }

      &:not(:last-of-type) {
        margin-bottom: 20px;
      }
    }
  }

  .linkLogo {
    width: 100%;
    text-decoration: none;
    display: flex;
    align-items: flex-end;

    .text {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: bold;
      color: #fff;
      display: flex;
      flex-direction: column;
      margin-left: 10px;

      span {
        &:first-of-type {
          font-weight: 700;
          font-size: 14px;
          line-height: 125%;
        }

        &:last-of-type {
          font-weight: 300;
          font-size: 10px;
          line-height: 12px;
        }
      }
    }
  }

  .infoUserWrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-right: 10px;

    .infoUser {
      display: flex;
      align-items: center;
    }

    .avatar {
      height: 40px;
      width: 40px;
      object-fit: cover;
      object-position: center;
      margin-right: 10px;
    }

    .content {
      display: flex;
      flex-direction: column;
    }

    .name,
    .position {
      font-size: 12px;
      line-height: 100%;
      color: $white;
    }

    .position {
      opacity: 0.5;
      margin-top: 5px;
    }

    .icon {
      cursor: pointer;
    }
  }
}
