@import '../scss/index';

.customToastify {
  &.Toastify__toast-container {
    width: auto;
  }

  .Toastify__toast-body {
    padding: 0;
    font-family: 'Staatliches';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.15em;
    text-transform: uppercase;
  }

  .Toastify__toast-icon {
    width: auto;

    svg {
      width: 12px;
      height: 12px;
    }
  }

  .Toastify__toast-theme--colored {
    &.Toastify__toast--success {
      background: $success;
      color: $black;
    }

    &.Toastify__toast--error {
      background: $error;
    }

    &.Toastify__toast {
      padding: 10px 20px;
      min-height: auto;
      border-radius: 0;

      .Toastify__close-button {
        display: none;
      }
    }
  }
}
