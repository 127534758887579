//colors
$black: #1b1d1d;
$white: #fff;
$gray: #847a89;
$violet: #d06cff;
$turquoise: #00e1c8;
$error: #ff002e;
$success: #8fff00;
$orang: #ff8a00;
$yellow: #ebff00;
$blue: #5794f7;

//transition
$transitionEase: all 0.15s ease;

$backgroundDropDown: rgba(0, 0, 0, 0.05);
